
const typography = 
 {
  h1: {
    fontWeight: 500,
    fontSize: 35,
    fontFamily: "'Space Grotesk', sans-serif",
    "background": "linear-gradient(#CED0DA, #B1B3ED)",
    "WebkitBackgroundClip": "text",
    "color": "transparent",
    // "@media(max-width:767px)": {
    //   fontSize: "20px",
    // },
  },

  h2: {
    fontWeight: 500,
    fontSize: 30,
    fontFamily: "'Space Grotesk', sans-serif",
    "background": "linear-gradient(#CED0DA, #B1B3ED)",
    "WebkitBackgroundClip": "text",
    "color": "transparent",
  },
  h3: {
    fontWeight: 500,
    fontSize: 25,
    fontFamily: "'Space Grotesk', sans-serif",
    "background": "linear-gradient(#CED0DA, #B1B3ED)",
    "WebkitBackgroundClip": "text",
    "color": "transparent",
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    fontFamily: "'Space Grotesk', sans-serif",
    color: "#fff",
  },
  h5: {
    fontWeight: 500,
    fontSize: 18,
    color: "#fff",
    fontFamily: "'Space Grotesk', sans-serif",
  },
  h6: {
    fontWeight: 500,
    fontSize: 16,
    fontFamily: "'Space Grotesk', sans-serif",
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "'Space Grotesk', sans-serif",
  },
  body1: {
    fontSize: 16,
    color: "rgb(235 238 255 / 70%)",
    fontFamily: "'Space Grotesk', sans-serif",
  },
  body2: {
    fontSize: 14,
    fontWeight: "300",
    color: "rgb(235 238 255 / 70%)",
    lineHeight: "21px",
    fontFamily: "'Space Grotesk', sans-serif",
  },
};
export default typography;
